<template>
    <div>
        <main class="col-lg-10 col-md-9 col-sm-9  fill">
            <h1>Thank you for registering with CDI!</h1>
            <p>A CDI representative will review your registration shortly, and if approved, will grant you access to quoting features.</p>
            <p>If you feel you have reached this page in error or to expedite your approval please email: <a href="mailto:ar@cdicurbs.com">ar@cdicurbs.com</a></p>
        </main>
    </div>
</template>

<script>

export default {
    name: "Rejected",
    components: {

    },
    created() {
        this.$appStore.setTitle(['New CDI Account']);
    },
}
</script>

<style scoped>

</style>
